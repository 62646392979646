import React from 'react'
import styled from "styled-components"
import {useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image"
import { Button } from '../elements/button';
import backgroundImageSand from "../assets/images/sand_texture-bg.jpg"

const Cabins = ({heading}) => {
  const data = useStaticQuery(graphql`
    query CabinsQuery {
      allCabinsJson {
        edges {
          node {
            alt
            path
            buttonLabel
            img {
              childImageSharp {
                fluid (maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            title
          }
        }
      }
    }
  `)

  function getCabins(data) {
    const cabinsArray = [];
    data.allCabinsJson.edges.forEach((item, index) => {
      cabinsArray.push(
        <ProductButton to={item.node.path}>
        <ProductCard key={index}>
          <ProductImg
            alt={item.node.alt} 
            fluid={item.node.img.childImageSharp.fluid} 
          />
          <ProductInfo>
            <TextWrap>
              <ProductTitle>{item.node.buttonLabel} - {item.node.title}</ProductTitle>
            </TextWrap>
            <Button 
              to={item.node.path} 
              primary="true" 
              round="true" 
              css={`
                position: absolute;
                top: 320px;
                font-size: 14px;
              `}
            >
              View Details
            </Button>
          </ProductInfo>
        </ProductCard>
        </ProductButton>
      )
    })
    return cabinsArray;
  }

  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <ProductsDescription>
         <p>Gold Coast Retreat offers two vacation cottages nestled in a rain forest clearing with southern 
           exposure on the wild west coast of Vancouver Island, just minutes away from Tofino...</p>
        <Button to="/aboutGoldCoast" primary="true" round="true">Read More</Button>
      </ProductsDescription>
      <ProductsWrapper>{getCabins(data)}</ProductsWrapper>
    </ProductsContainer>
  )
}

export default Cabins

const ProductsContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #fff;
  color: #fff;
  background-image: url(${backgroundImageSand});
`

const ProductsHeading  = styled.h2`
  font-size: clamp(1.2rem, 5vw, 1.75rem);
  text-align: center;
  margin-bottom: 3rem;
  color: #373a3c;
  text-transform: uppercase;
`

const ProductsDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  padding-bottom: 5rem;
  justify-self: center;
  line-height: 1.6;

  p {
    width: 80%;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    padding-bottom: 1.5rem;
    color: #373a3c;
  }
`

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: center;
  padding: 0 2rem;
  
  @media screen and (max-width: 940px){
    grid-template-columns: 1fr;
  }
`

const ProductButton = styled.a`
  width: 100%;
  height: 400px;
`

const ProductCard = styled.div`
  line-height: 1;
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  vertical-align: top;  
  box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
`

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem;

  @media screen and (max-width: 280px){
    padding: 0 1rem;
  }
`

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 275px;
`

const ProductTitle = styled.h3`
  font-weight: 400;
  font-size: clamp(1rem, 5vw, 1.5rem);
  margin-left: 0.5rem;
  text-shadow: 2px 2px 4px #000000;
  color: #fff;
`

const ProductImg = styled(Img)`
  height: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 5px;

  -webkit-transition: all .5s ease; /* Safari and Chrome */
  -moz-transition: all .5s ease; /* Firefox */
  -ms-transition: all .5s ease; /* IE 9 */
  -o-transition: all .5s ease; /* Opera */
  transition: all .5s ease;

  &:hover {
    -webkit-transform:scale(1.25); /* Safari and Chrome */
    -moz-transform:scale(1.25); /* Firefox */
    -ms-transform:scale(1.25); /* IE 9 */
    -o-transform:scale(1.25); /* Opera */
    transform:scale(1.25);
  }
`








