import React from 'react'
import styled from "styled-components"
import Slider from "./slider"
import { Button } from '../elements/button'

export const Hero = () => {
  return (
    <HeroContainer>
      <HeroBg>
        <NewSlider />
      </HeroBg>
      <HeroContent>
        <HeroItems>
          <HeroH1>Gold Coast Retreat</HeroH1>
          <HeroP>Tofino, BC</HeroP>
          <Button primary="true" big="true" round="true" to="/reservations">Book Now</Button>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -117px;
  color: #fff;
`
const HeroBg = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
`
const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 117px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1400px) / 2);
`
const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  line-height: 1.6;
`
const HeroH1 = styled.h1`
  font-weight: bold;
  color: #fff;
  font-size: clamp(1.5rem, 6vw, 4rem);
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  padding: 0 1rem;
`
const HeroP = styled.p`
  color: #fff;  
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
  font-weight: 400;
`

const NewSlider = styled(Slider)`
  height: 100%!important;
`