import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from 'gatsby'

const Slider = () => {

  const data = useStaticQuery(graphql`
  query {
    sliderImages: allFile(filter: {relativeDirectory: {eq: "slider"}, ext: {regex: "/(jpg)|(png)|(jpeg)/"}}, sort: {fields: relativePath, order: ASC}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`)


return (
  <>
    <Carousel>
      {data.sliderImages.edges.map(image => (
        <Carousel.Item key={image.id}>
          <Img 
            fluid={image.node.childImageSharp.fluid} 
            alt={image.node.base.split('_').join(' ').split('.')[0]}
            css={`height: 100vh;`}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  </>
    
)}


export default Slider

