import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Cabins from "../components/cabins"
import About from "../components/aboutSection"
import 'bootstrap/dist/css/bootstrap.min.css';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Tofino Cabin Rentals" />
      <Hero />
      <Cabins heading="Tofino cabin rentals at chesterman beach"/>
      <About />
    </Layout>
  )
}

export default IndexPage
