import React from 'react'
import styled from "styled-components"
import {Link} from "gatsby"
import aboutCardImage from "../assets/images/about_card.jpg"
import ratesCardImage from "../assets/images/sand_texture.jpg"

const About = () => {
  return (
    <AboutContainer>
        <AboutTofino>
          <AboutCard>
            <AboutLink to="/aboutTofino"><h3>About Tofino</h3></AboutLink>
          </AboutCard>
        </AboutTofino>
        <Rates>
          <AboutCard>
            <AboutLink to="/rates"><h3>Our Rates</h3></AboutLink>
          </AboutCard>
        </Rates>
    </AboutContainer>
  )
}

export default About

const AboutContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  color: #000;
  min-height: 370px;
  display: grid;
  grid-template-columns: 2fr 1.5fr;
  padding: 0;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
    min-height: 500px;
  }
`

const AboutTofino = styled.div`
  background-image: url(${aboutCardImage});
  background-position: center;
  background-size: cover;
  border-top: 2px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
`
const Rates = styled.div`
  background-image: url(${ratesCardImage});
  background-position: center;
  background-size: cover;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 1px solid #fff;
`

const AboutCard = styled.div`
  display: flex;
  justify-content: center;
`

const AboutLink = styled(Link)`
    margin: 170px;
    position: absolute;
    box-shadow: none;
    text-decoration: none;

    h3 {
      color: #fff;
      font-size: clamp(1.2rem, 5vw, 2.5rem);
      font-style: italic;
      
      
      &:hover{
        color: rgba(255,255,255,0.4);
        transition: color 0.5s;
      } 
    }

    @media screen and (max-width: 868px){
      margin: 100px;
    }

    &:hover{
      text-decoration: none;
      box-shadow: none;
    } 
`
